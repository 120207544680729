import React, { lazy } from 'react'
import loadable from '@components/Loadable'
// import { GuestGuard } from 'guards'
import { useRoutes } from 'react-router-dom'

const PublicLayout = loadable(lazy(() => import('../Layouts/Public')))
// const PrivateLayout = loadable(lazy(() => import('../Layouts/Private')))
const Home = loadable(lazy(() => import('pages/Home')))
const Contact = loadable(lazy(() => import('pages/Contact')))
const Document = loadable(lazy(() => import('pages/Otts')))
const Offer = loadable(lazy(() => import('pages/Offer')))
const Plans = loadable(lazy(() => import('pages/Plans')))
const Profile = loadable(lazy(() => import('pages/Profile')))
const Auth = loadable(lazy(() => import('pages/Auth')))

const Router = () =>
  useRoutes([
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: 'contact',
          element: <Contact />,
        },
        {
          path: 'otts',
          children: [
            {
              index: true,
              element: <Home />,
            },
            {
              path: ':ottsId',
              element: <Document />,
            },
          ],
        },
        {
          path: 'offer',
          element: <Offer />,
        },
        {
          path: 'plans',
          element: <Plans />,
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'auth/verify',
          element: <Auth />,
        },
        // { path: '*', element: <Navigate to="/auth/login" replace /> },
      ],
    },
  ])

export default Router
