import { all, call, put, takeLatest } from 'redux-saga/effects'

import { GET_OTTS_REQUEST } from './constants'
import OTTS_API from '../api'
import { getOttsSuccess } from './actions'

function* getOtts({ ottsId }) {
  try {
    console.log({ ottsId })
    const response = yield call(OTTS_API.getOtts, ottsId)
    return yield put(getOttsSuccess({ payload: { data: response } }))
  } catch (error) {
    console.log(error)
  }
}

function* Saga() {
  yield all([takeLatest(GET_OTTS_REQUEST, getOtts)])
}

export default Saga
