import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
// import ErrorCatcher from 'src/__components/Error'
// import { ToastContainer } from 'components/Toast/components'
import '@style'
import App from 'app'
import { history, persistor, store } from 'app/Store'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('Looks like we are in development mode!')
}

const root = createRoot(document.getElementById('root'))
root.render(
  // <ErrorCatcher>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter history={history}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </ErrorCatcher>,
)
