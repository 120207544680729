import { all, call, put, takeLatest } from 'redux-saga/effects'

import { GET_ALL_OTTSES_REQUEST } from './constants'
import OTTS_API from '../api'

function* getAllOtteses({
  meta: {
    table,
    callback,
    page: queryPage,
    size: querySize,
    sortBy,
    directionSort,
    search,
    searchBy,
  },
}) {
  try {
    const { page, limit, totalResults, results } = yield call(
      OTTS_API.getAllOttses,
      {
        page: queryPage,
        limit: querySize,
        sortBy,
        directionSort,
        search,
        searchBy,
      },
    )
    if (callback) {
      return yield put(
        callback({
          meta: {
            table,
            page,
            size: limit,
            sortBy,
            directionSort,
            search,
            searchBy,
            totalSize: totalResults,
          },
          payload: { response: results },
        }),
      )
    }
  } catch (error) {
    // openNotification({
    //   type: 'error',
    //   message: 'The list of surveys could not be received.',
    // })
    console.error(error)
  }
}

function* Saga() {
  yield all([takeLatest(GET_ALL_OTTSES_REQUEST, getAllOtteses)])
}

export default Saga
