import createAction from 'utils/createAction'

import {
  GET_OTTS_FAILURE,
  GET_OTTS_REQUEST,
  GET_OTTS_SUCCESS,
} from './constants'

export const getOttsRequest = createAction(GET_OTTS_REQUEST)
export const getOttsSuccess = createAction(GET_OTTS_SUCCESS)
export const getOttsFailure = createAction(GET_OTTS_FAILURE)
