import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectAuth = (state) => state.auth

export const makeSelectTokenRefresh = () =>
  createSelector(
    selectAuth,
    (authState) =>
      authState &&
      authState.get('tokens') &&
      get(authState.get('tokens'), 'refresh.token'),
  )

export const makeSelectToken = () =>
  createSelector(
    selectAuth,
    (authState) => authState && authState.get('tokens'),
  )

export const makeSelectUser = () =>
  createSelector(selectAuth, (authState) => authState && authState.get('user'))
