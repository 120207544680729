import React, { useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { makeSelectTokenRefresh } from '../../../app/selectors'
import { refreshToken, testToken } from '../redux/action'
import { useNavigate } from 'react-router-dom'

function AuthChecker({ children, refresh, test, tokenRefresh }) {
  const tokenInterval = useRef(0)
  const navigate = useNavigate()
  const clear = useCallback(() => {
    window.clearInterval(tokenInterval.current)
    tokenInterval.current = 0
  }, [tokenInterval])

  // Проверяем валидность токена при инициализации приложения
  useEffect(() => {
    if (tokenRefresh) {
      test()
    }
  }, [test])

  // Обновляем токен для поддержания сессии
  useEffect(() => {
    if (tokenRefresh) {
      const interval = 1000 * 60 * 5
      if (!tokenInterval.current) {
        refresh({ navigate })
        tokenInterval.current = window.setInterval(() => {
          refresh({ navigate })
        }, interval)
      }
    } else {
      clear()
    }
  }, [refresh, tokenRefresh, clear])
  return <>{children}</>
}

const mapStateToProps = createStructuredSelector({
  tokenRefresh: makeSelectTokenRefresh(),
})

const mapDispatchToProps = {
  refresh: refreshToken,
  test: testToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthChecker)
